// You can specify which plugins you need
import { Tooltip, Toast, Popover } from "bootstrap";
import "lazysizes";
import Swiper from "swiper/bundle";
import L from "leaflet/dist/leaflet";
import AOS from "aos";

// Aggancio LeafLet a window
window.L = L;

const body = document.body;
const header = document.querySelector(".e-menu");

const tyElements = document.querySelectorAll(".ty-minus50");

const tyMinus50 = function (elms) {
  if (window.innerWidth > 991.98) {
    elms.forEach((element) => {
      element.style.height = `${element.children[0].offsetHeight / 2}px`;
    });
  } else {
    elms.forEach((element) => {
      element.style.height = `${element.children[0].offsetHeight}px`;
    });
  }
};

const resizeUtilities = function () {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  body.style.paddingTop = `${header.offsetHeight}px`;

  tyMinus50(tyElements);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    body.style.paddingTop = `${header.offsetHeight}px`;

    tyMinus50(tyElements);
  });
};

const homeSlider = function () {
  const setSlidesDuration = function () {
    const slides = document.querySelectorAll(".home-hero.swiper-slide");
    slides.forEach((slide) => {
      if (slide.classList.contains("isVideo")) {
        const video = slide.querySelector(".video-container video");

        video.onloadedmetadata = function () {
          slide.dataset.swiperAutoplay = this.duration * 1000;
        };
      } else {
        slide.dataset.swiperAutoplay = 6 * 1000;
      }
    });
  };

  setSlidesDuration();

  const homeSwiper = new Swiper(".home-slider", {
    // Optional parameters
    init: false,
    autoplay: false,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 0,

    // Navigation arrows
    navigation: {
      nextEl: ".homeSlider-swiper-button-next",
      prevEl: ".homeSlider-swiper-button-prev",
    },
    on: {
      slideChangeTransitionStart: function () {
        document
          .querySelectorAll(".home-slider .aos-init")
          .forEach(function (el) {
            el.style.visibility = "hidden";
            el.classList.remove("aos-animate");
          });
      },
      slideChangeTransitionEnd: function () {
        document
          .querySelectorAll(".home-slider .aos-init")
          .forEach(function (el) {
            el.style.visibility = "visible";
            el.classList.add("aos-animate");
          });
      },
    },
  });

  const firstVideo = document.querySelector(
    ".home-hero.swiper-slide .video-container video"
  );

  // Wait first video to load before init Swiper and start Autoplay
  if (firstVideo) {
    firstVideo.onloadeddata = function () {
      homeSwiper.init();
      homeSwiper.autoplay.start();
    };
  }
};

const categorySlider = function () {
  const swiper = new Swiper(
    ".categories-grid:not(.categories-grid--no-slider)",
    {
      // Optional parameters

      loop: false,
      slidesPerView: 5,
      spaceBetween: 0,

      // Navigation arrows
      navigation: {
        nextEl: ".categorySlider-swiper-button-next",
        prevEl: ".categorySlider-swiper-button-prev",
      },
      breakpoints: {
        991.98: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        1199.98: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
      },
    }
  );
};
const productsSlider = function () {
  const swiper = new Swiper(".products-slider", {
    // Optional parameters

    slidesPerView: 3,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    effect: "coverflow",
    coverflowEffect: {
      rotate: 0,
      slideShadows: false,
      depth: 0,
    },

    // Navigation arrows
    navigation: {
      nextEl: ".productsSlider-swiper-button-next",
      prevEl: ".productsSlider-swiper-button-prev",
    },

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      767.98: {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
      },
      991.98: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },
  });
};

const initSliders = function () {
  homeSlider();
  categorySlider();
  productsSlider();
};

const scroll = function () {
  // const headerHeight = document.querySelector(".js-header").offsetHeight;

  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 100;

  body.classList.add("onTop");

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      body.classList.add("onTop");
      body.classList.remove("scroll");
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
      body.classList.remove("onTop");
      body.classList.add("scroll");
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
      body.classList.remove("onTop");
      body.classList.add("scroll");
    }
    lastScroll = currentScroll;
  });
};

const backToTop = function () {
  //Get the button
  const backToTop = document.getElementById("backToTop");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      backToTop.classList.add("active");
    } else {
      backToTop.classList.remove("active");
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {}

  backToTop.addEventListener("click", function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });
};

document.addEventListener("DOMContentLoaded", function () {
  resizeUtilities();
  scroll();
  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 600, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
  });
  initSliders();
  backToTop();
});
